/* =============================================================================
   MAPS (components/_maps.scss)
   ========================================================================== */

.c-map-contact {
	height: 18.75rem;

	@include media-breakpoint-up( md ) {
		width: 40%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
	}

	&::before {
		@include media-breakpoint-up( md ) {
			content: '';
			display: block;
			height: 12.5rem;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: linear-gradient( 180deg, rgba( color( extra, dark ), .8 ) 0%, rgba( color( extra, dark ), 0 ) 100% );
		}
	}

	iframe {
		display: block;
	}
}
