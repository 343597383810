/* =============================================================================
   SPLIT-GRID (components/_split-grid.scss)
   ========================================================================== */

.c-split-grid {
	position: relative;
}



/* Content
   ========================================================================== */

.c-split-grid__content {
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;

	@include media-breakpoint-up( lg ) {
		min-height: 43.75rem;
	}
}

.c-split-grid__content--empty {
	@include media-breakpoint-up( lg ) {
		min-height: 16.25rem;
	}
}



/* Inner
   ========================================================================== */

.c-split-grid__inner {
	padding-top: 3.75rem;
	padding-bottom: 2.5rem;

	@include media-breakpoint-up( lg ) {
		padding-top: 10rem;
		padding-bottom: 8.75rem;
	}
}



/* Background
   ========================================================================== */

.c-split-grid__background {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;

	@include media-breakpoint-up( md ) {
		width: 50%;
	}

	.c-split-grid--reverted & {
		right: 0;
		left: auto;
	}
}

.c-split-grid__background--60\% {
	@include media-breakpoint-up( md ) {
		width: 60%;
	}
}

.c-split-grid__background--70\% {
	@include media-breakpoint-up( md ) {
		width: 70%;
	}
}



/* Image
   ========================================================================== */

.c-split-grid__image {
	position: relative;
	width: 100%;
	height: 18.75rem;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	z-index: 1;

	@include media-breakpoint-up( md ) {
		display: block;
		width: 50%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
	}

	.c-split-grid--reverted & {
		right: auto;
		left: 0;
	}
}

.c-split-grid__image--30\% {
	@include media-breakpoint-up( md ) {
		width: 30%;
	}
}

.c-split-grid__image--40\% {
	@include media-breakpoint-up( md ) {
		width: 40%;
	}
}

.c-split-grid__image--h70\% {
	@include media-breakpoint-up( md ) {
		height: 70%;
	}
}

.c-split-grid__image--spacing-1 {
	@include media-breakpoint-up( lg ) {
		top: 7.5rem;
	}
}

.c-split-grid__image--spacing-2 {
	@include media-breakpoint-up( lg ) {
		top: -5rem;
		height: calc( 100% + 5rem );
	}
}

.c-split-grid__image--spacing-3 {
	@include media-breakpoint-up( lg ) {
		bottom: -5rem;
		height: calc( 100% + 5rem );
	}
}

.c-split-grid__image--spacing-4 {
	@include media-breakpoint-up( lg ) {
		top: -3.75rem;
	}
}

.c-split-grid__image--spacing-5 {
	@include media-breakpoint-up( lg ) {
		bottom: -10rem;
		height: calc( 100% + 10rem );
	}
}

.c-split-grid__image--spacing-6 {
	@include media-breakpoint-up( lg ) {
		top: -10rem;
		height: calc( 100% + 10rem );
	}
}
