/* =============================================================================
   HERO (components/_hero.scss)
   ========================================================================== */

.c-hero {
	position: relative;
	background-size: cover;

	@include media-breakpoint-up( md ) {
		background-image: none !important; /* stylelint-disable-line declaration-no-important */
	}

	.o-row {
		min-height: 100vh;
		align-items: center;
	}
}

.c-hero--dark {
	color: color( extra, white );
	background-color: color( extra, dark );
}



/* Inner
   ========================================================================== */

.c-hero__inner {
	padding-top: 14.25rem;
	padding-bottom: 3.125rem;

	@include media-breakpoint-up( md ) {
		padding-top: 10rem;
	}

	@include media-breakpoint-up( lg ) {
		padding-top: 11.875rem;
		padding-bottom: 7.5rem;
	}
}



/* Image
   ========================================================================== */

.c-hero__image {
	height: 18.75rem;
	width: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	@include media-breakpoint-up( md ) {
		height: 100%;
		width: 50%;
		position: absolute;
		top: 0;
		right: 0;
	}

	&::before {
		@include media-breakpoint-up( md ) {
			content: '';
			display: block;
			height: 8.125rem;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: linear-gradient( 180deg, rgba( color( extra, dark ), .5 ) 0%, rgba( color( extra, dark ), 0 ) 100% );
		}
	}
}
