/* =============================================================================
   FORM (compnents/_form.scss)
   ========================================================================== */

.c-form {
	.gform_fields {
		margin-bottom: 1rem;
	}

	.gfield {
		padding-left: 0;

		&::before {
			content: none;
		}

		.hidden_sub_label {
			display: none;
		}
	}

	.gform_button {
		margin-top: .625rem;

		@include media-breakpoint-up( lg ) {
			margin-top: 1.25rem;
		}
	}

	.validation_message {
		position: relative;
		bottom: .8125rem;
		color: color( primary );
	}

	.validation_error {
		display: none;
	}
}
