/* =============================================================================
   TYPOGRAPHY (elements/_typography.scss)
   ========================================================================== */

/* Headings
   ========================================================================== */

h1, h2, h3, h4, h5, h6 {
	font-family: $font-family-secondary;
	font-weight: $font-weight-semibold;
	line-height: $line-height-headings;
}



/* Links
   ========================================================================== */

a[ href^='mailto:' ],
a[ href^='tel:' ] {
	color: currentColor;
}

// links within text

p {
	a {
		border-bottom: 1px solid transparent;
		font-weight: $font-weight-medium;
		transition: border-color .2s;

		&:hover {
			border-color: currentColor;
		}
	}
}



/* Lists
   ========================================================================== */

ul {
	@extend .o-list-bare;
	text-align: left;

	li {
		position: relative;
		padding-left: 2rem;

		&::before {
			content: '';
			display: block;
			width: .875rem;
			height: 1px;
			position: absolute;
			top: .75em;
			left: 0;
			background-color: currentColor;
		}
	}
}



/* Tables
   ========================================================================== */

table {
	width: auto;

	td {
		vertical-align: text-top;

		&:first-child {
			padding-right: .5rem;
			white-space: nowrap;

			span {
				display: block;
				padding-left: 2rem;
				position: relative;

				&::before {
					content: '';
					display: block;
					width: .875rem;
					height: 1px;
					position: absolute;
					top: .75em;
					left: 0;
					background-color: currentColor;
				}
			}
		}

		&:last-child {
			padding-left: .5rem;
		}
	}
}



/* Other
========================================================================== */

strong {
	font-weight: $font-weight-medium;
}

