/* =============================================================================
   SOCIAL-NAV (compnents/_social-nav.scss)
   ========================================================================== */

.c-social-nav {
	@extend .o-list-inline;
	font-size: 1.5rem;
	text-align: inherit;
}



/* Item
   ========================================================================== */

.c-social-nav__item {
	@extend .o-list-inline__item;
	@extend .o-list-bare__item;

	& + & {
		margin-left: 1.125rem;

		@include media-breakpoint-up( sm ) {
			margin-left: 1.5625rem;
		}
	}
}



/* Link
   ========================================================================== */

.c-social-nav__link {
	color: currentColor;
	transition: color .2s;
	display: block;

	&:hover {
		color: color( primary );
	}
}
