/* =============================================================================
   LIST (objects/_list.scss)
   ========================================================================== */

/* List Bare
   ========================================================================== */

/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */

.o-list-bare__item {
	padding-left: 0;

	&::before {
		display: none;
	}
}
