/* =============================================================================
   HEADER (components/_header.scss)
   ========================================================================== */

.c-header {
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 100;
	transition: background-color .2s;

	@include media-breakpoint-up( sm ) {
		position: fixed;
	}

	@include media-breakpoint-up( md ) {
		color: color( extra, white );
	}
}

.c-header--static {
	@include media-breakpoint-up( sm ) {
		position: absolute;
	}
}

.c-header--light {
	color: color( extra, white );
}

.c-header--has-background {
	color: color( extra, dark );
	background-color: color( extra, white );

	&.c-header--light {
		color: color( extra, white );
		background-color: color( extra, dark );
	}
}



/* Inner
   ========================================================================== */

.c-header__inner {
	padding-top: 1.375rem;
	padding-bottom: 1.375rem;

	@include media-breakpoint-up( sm ) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}



/* Logo
   ========================================================================== */

.c-header__logo {
	display: inline-block;
	margin-bottom: 1rem;

	@include media-breakpoint-up( sm ) {
		margin-bottom: 0;
	}
}



/* Menu
   ========================================================================== */

.c-header__menu {
	@extend .o-list-bare;
	display: inline-flex;
	align-items: center;
	text-transform: uppercase;
	margin-bottom: 0;

	li {
		@extend .o-list-bare__item;
	}

	li + li {
		margin-left: 1.875rem;

		@include media-breakpoint-up( sm ) {
			margin-left: 2.5rem;
		}

		@include media-breakpoint-up( md ) {
			margin-left: 3.125rem;
		}
	}
}

.c-header__menu-link {
	font-weight: $font-weight-normal;
	font-size: font-size( sm );
	color: currentColor;
	border-bottom: 1px solid transparent;
	transition: color .2s, border-bottom-color .2s;
	padding-bottom: .1875rem;

	@include media-breakpoint-up( sm ) {
		font-size: font-size( base );
	}

	&:hover {
		border-bottom-color: currentColor;
	}
}

.c-header__menu-cta {
	@extend .c-button;
	@extend .c-button--primary;
	font-size: font-size( sm );
	padding-top: .375rem;
	padding-right: .625rem;
	padding-bottom: .375rem;
	padding-left: .625rem;

	@include media-breakpoint-up( sm ) {
		font-size: inherit;
		padding-top: $buttons-padding-y;
		padding-right: $buttons-padding-x;
		padding-bottom: $buttons-padding-y;
		padding-left: $buttons-padding-x;
	}
}
