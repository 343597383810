/* =============================================================================
   GRID (objects/_grid.scss)
   ========================================================================== */

/**
 * Flexbox based grid.
 */

/* Container
   ========================================================================== */

.o-container-fluid {
	max-width: 100rem;
}
