/* =============================================================================
   ALERT (components/_alert.scss)
   ========================================================================== */

.c-alert {
	padding-top: .625rem;
	padding-bottom: .625rem;
	background-color: color( primary );
	text-align: center;
}

.c-alert__inner {
	position: relative;

	@include media-breakpoint-up( sm ) {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.c-alert__text {
	margin-bottom: 0;
	background-color: color( primary );
	font-size: font-size( sm );
	font-weight: $font-weight-normal;
	color: color( extra, white );
}

.c-alert__cta {
	display: block;
	margin-top: .3125rem;
	margin-bottom: .3125rem;

	@include media-breakpoint-up( sm ) {
		margin-top: 0;
		margin-bottom: 0;
		margin-left: .9375rem;
	}
}

.c-alert__close {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY( -50% );

	svg {
		display: block;
	}
}
