/* =============================================================================
   TYPOGRAPHY (settings/_typography.scss)
   ========================================================================== */

/* Base
   ========================================================================== */

$font-size-base: 16px !default;
$line-height-base: 1.5 !default;
$font-family-base: 'Ideal Sans A', 'Ideal Sans B', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default;
$font-family-secondary: 'Quarto A', 'Quarto B', serif !default;



/* Font Sizes
   ========================================================================== */

/**
 * Headings sizes used in elements and utilities layer.
 */

$font-sizes-headings: (
	h1: 4.0625rem,
	h2: 3.125rem,
	h3: 2.25rem,
	h4: 1.875rem,
	h5: 1.375rem,
	h6: 1.125rem,
) !default;

/**
 * Other sizes used in utilities layer for `font-size` utilities.
 */

$font-sizes-other: (
	xxs: .625rem,
	xs: .75rem,
	sm: .875rem,
	base: 1rem,
	xl: 4.0625rem,
) !default;

/**
 * Merge all font size maps into one.
 */

$font-sizes: map-merge(
	$font-sizes-headings, $font-sizes-other
) !default;



/* Line Heights
   ========================================================================== */

$line-height-headings: 1.2 !default;



/* Font Weights
   ========================================================================== */

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
