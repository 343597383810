/* =============================================================================
   TYPOGRAPHY (utilities/_typography.scss)
   ========================================================================== */

/* stylelint-disable declaration-no-important */

/* Font Size
   ========================================================================== */

/**
 * Utilities for controlling the font size of an element.
 */

/**
 * Redefine all of our basic heading styles against utility classes so as to
 * allow for double stranded heading hierarchy, e.g. we semantically need an H2,
 * but we want it to be sized like an H1.
 */

@each $breakpoint-key, $breakpoint-value in $breakpoints {
	@if $breakpoint-key != xs {
		@include media-breakpoint-up( #{$breakpoint-key} ) {
			@each $font-size-name, $font-size-value in $font-sizes-headings {
				.u-#{$font-size-name}\@#{$breakpoint-key} {
					font-size: $font-size-value !important;
				}
			}
		}
	}
}

/**
 * Font size classes generated from `$font-sizes-other` map.
 */

@each $breakpoint-key, $breakpoint-value in $breakpoints {
	@if $breakpoint-key != xs {
		@include media-breakpoint-up( #{$breakpoint-key} ) {
			@each $font-size-name, $font-size-value in $font-sizes-other {
				.u-text-#{$font-size-name}\@#{$breakpoint-key} {
					font-size: $font-size-value !important;
				}
			}
		}
	}
}



/* Font Family
   ========================================================================== */

/**
 * Utilities for controlling the font family of an element.
 */

.u-font-base {
	font-family: $font-family-base !important;
}

.u-font-secondary {
	font-family: $font-family-secondary !important;
}



/* Font Weight
   ========================================================================== */

/**
 * Utilities for controlling the font weight of an element.
 */

.u-font-normal {
	font-weight: $font-weight-normal !important;
}

.u-font-medium {
	font-weight: $font-weight-medium !important;
}

