/* =============================================================================
   BUTTONS (components/_buttons.scss)
   ========================================================================== */

.c-button {
	text-transform: uppercase;
	font-weight: $font-weight-normal;
	max-width: 100%;
	border-radius: 0;
	padding-right: 1.375rem;
	padding-left: 1.375rem;

	@include media-breakpoint-up( md ) {
		padding-top: $buttons-padding-y;
		padding-right: $buttons-padding-x;
		padding-bottom: $buttons-padding-y;
		padding-left: $buttons-padding-x;
	}
}



/* Variants
   ========================================================================== */

/**
 * Variations with primary background color.
 */

.c-button--primary {
	color: color( extra, white );
}

/**
 * Variations with border around button edge.
 */

.c-button--outline-light {
	color: color( extra, white );
	border: 1px solid color( extra, white );
	transition: color .2s, background-color .2s;

	&:hover {
		color: color( extra, dark );
		background-color: color( extra, white );
	}
}

.c-button--outline-dark {
	color: color( extra, dark );
	border: 1px solid color( extra, dark );
	transition: color .2s, background-color .2s;

	&:hover {
		color: color( extra, white );
		background-color: color( extra, dark );
	}
}

/**
 * Size variations.
 */

.c-button--sm {
	padding: .375rem 1.25rem;
	font-size: font-size( sm );
}

/**
 * Width variations.
 */

.c-button--wide {
	width: 13.75rem;
}
