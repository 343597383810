/* =============================================================================
   FORMS (elements/_forms.scss)
   ========================================================================== */

input:not( [ type='submit' ] ),
textarea {
	display: block;
	width: 100%;
	font-size: font-size( sm );
	font-weight: $font-weight-light;
	border: 0;
	background-color: color( extra, gray-100 );
	padding-top: .75rem;
	padding-right: 1.25rem;
	padding-bottom: .75rem;
	padding-left: 1.25rem;
	margin-bottom: 1rem;

	&:focus {
		outline: none;
	}

	&::placeholder {
		font-style: italic;
	}
}

textarea {
	height: 5rem;
}

label {
	display: block;
	font-size: font-size( sm );
	font-weight: $font-weight-normal;
	margin-bottom: .625rem;
}

input[ type='submit' ] {
	@extend .c-button;
	@extend .c-button--outline-dark;
	@extend .c-button--wide;
}
