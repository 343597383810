/* =============================================================================
   AGILO-LABEL (components/_agilo-label.scss)
   ========================================================================== */

.c-agilo-label__link {
	vertical-align: text-bottom;
}



/* Pill
   ========================================================================== */

.c-agilo-label__pill-1,
.c-agilo-label__pill-2,
.c-agilo-label__pill-3 {
	transition: fill .2s;
}

.c-agilo-label__pill-1 {
	.c-agilo-label__link:hover & {
		fill: #23c4b3;
	}
}

.c-agilo-label__pill-2 {
	.c-agilo-label__link:hover & {
		fill: #e9c430;
	}
}

.c-agilo-label__pill-3 {
	.c-agilo-label__link:hover & {
		fill: #dd4825;
	}
}
