/* =============================================================================
   FOOTER (compnents/_footer.scss)
   ========================================================================== */

.c-footer {
	color: color( extra, white );
	background-color: color( extra, dark );
	padding-top: 1.875rem;

	@include media-breakpoint-up( lg ) {
		padding-top: 3.125rem;
	}
}



/* Top and bottom
   ========================================================================== */

.c-footer__top,
.c-footer__bottom {
	flex-wrap: wrap;
	justify-content: space-between;
}

.c-footer__top {
	display: flex;
	font-size: font-size( sm );
}

.c-footer__bottom {
	font-size: font-size( xxs );
	font-weight: $font-weight-normal;
	text-transform: uppercase;
	border-top: 1px solid color( extra, white );
	padding-top: 1.125rem;
	padding-bottom: 1.125rem;

	@include media-breakpoint-up( lg ) {
		display: flex;
		margin-top: 7.5rem;
	}

	p {
		margin-bottom: 0;
	}
}



/* Column
   ========================================================================== */

.c-footer__column {
	width: 50%;
	flex: 0 0 50%;

	@include media-breakpoint-up( lg ) {
		width: initial;
		flex: initial;
	}

	.c-footer__bottom & {
		width: 100%;
		flex: 0 0 100%;

		@include media-breakpoint-up( lg ) {
			width: 50%;
			flex: 0 0 50%;
		}
	}
}

.c-footer__column--logo {
	display: block;
	width: 100%;
	flex: 0 0 100%;
	margin-bottom: 1.875rem;

	@include media-breakpoint-up( lg ) {
		width: initial;
		flex: initial;
	}
}



/* Logo
   ========================================================================== */

.c-footer__logo {
	max-width: 8.75rem;

	@include media-breakpoint-up( lg ) {
		max-width: initial;
	}
}



/* Content
   ========================================================================== */

.c-footer__title {
	font-weight: $font-weight-bold;
	text-transform: uppercase;
	margin-bottom: 0;
	margin-bottom: .3125rem;
}

.c-footer__info {
	line-height: 1.8;
}
