/* =============================================================================
   COLORS (settings/_colors.scss)
   ========================================================================== */

/*
 * Colors that are most represented in the design, every project must contain at
 * least one primary color.
 */

$color-primary: (
	primary: (
		default: #d35b23,
		light: #f3b69a,
	)
) !default;

/*
 * Colors that complement primary palette, every project must contain at least
 * one secondary color.
 */

$color-secondary: (
	secondary: (
		default: #f2f7f9,
	)
) !default;

/**
 * Colors used on notifications or interactive elements/forms with dynamic
 * states.
 */

$color-extra: (
	extra: (
		black: #000,
		dark: #0f0f0f,
		white: #fff,
		gray-100: #f3f3f3,
		gray-200: #c4c4c4,
	)
) !default;

/**
 * Merge all color maps into one.
 */

$colors: map-merge(
	map-merge( $color-primary, $color-secondary ), $color-extra
) !default;

/**
 * Colors used for base things such as base text color and links color.
 *
 * Don't use `color()` mixin in following variables, they're not available in
 * this (settings) layer.
 */

$color-base: color( extra, dark ) !default;
$color-links: color( primary ) !default;

/**
 * Set a specific jump point for requesting color jumps.
 */

$color-shade-percentage: 10% !default;
